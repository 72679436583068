<!--
 * @Descripttion: 参与活动列表
 * @version: 
 * @Author: alex.yang
 * @Date: 2022-08-18 16:04:10
 * @LastEditors: alex.yang
 * @LastEditTime: 2022-08-31 15:18:21
-->
<template>
    <div class="activityDataInfo">
        <div class="title-wrap">
            <div class="tag"></div>
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ name: 'brandData' }">品牌数据</el-breadcrumb-item>
                <el-breadcrumb-item>活动详情<span>-{{info.enterprise_brand?info.enterprise_brand.name:''}}-({{info.pname}})</span></el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <div class="case-wrap">
        <el-tabs v-model="activeName" type="card">
            <el-tab-pane label="参与活动" name="first">
                <participatingActivity ref="participatingActivity"></participatingActivity>
            </el-tab-pane>
            <el-tab-pane label="未参与活动" name="second">
                <notParticipatingActivitie ref="notParticipatingActivitie"></notParticipatingActivitie>
            </el-tab-pane>
            <!-- <el-tab-pane label="未参与活动" name="thirdly">
                <notParticipatingActivitie ref="notParticipatingActivitie"></notParticipatingActivitie>
            </el-tab-pane> -->
        </el-tabs>
        </div>
    </div>
</template>

<script>
import notParticipatingActivitie from './activityComponents/notParticipatingActivitie'
import participatingActivity from './activityComponents/participatingActivity'
export default {
    components: {
      notParticipatingActivitie,
      participatingActivity
    },
    data () {
        return {
            activeName: 'first',
            info: {}
        }
    },
    mounted () {
        this.info = JSON.parse(localStorage.getItem('activity'));
    },
}
</script>

<style lang='scss'>
.activityDataInfo{
    background: rgb(240, 242, 245);
    padding: 10px 25px;
    height: calc(100vh - 80px);
    overflow: hidden;
    .title-wrap{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 5px 0;
        .tag{
            width: 4px;
            height: 15px;
            background: #409EFF;
            border-radius: 10px;
            margin-right: 10px;
        }
        .title{
            font-size: 18px;
            font-weight: bold;
            padding-left: 10px;
        }
    }
    .case-wrap{
        background: #fff;
        padding: 20px 20px 5px 20px;
        margin-top: 20px;
        border-radius: 6px;
    }
    .el-breadcrumb__item{
        .el-breadcrumb__inner{
            color: #000;
            font-weight: bold;
        }
        .is-link{
            color: #333;
            font-weight: 300;
        }
    }
}
</style>
