<!--
 * @Descripttion: 参与活动
 * @version: 
 * @Author: alex.yang
 * @Date: 2022-08-18 16:09:01
 * @LastEditors: alex.yang
 * @LastEditTime: 2022-09-14 11:27:39
-->
<template>
    <div class="participatingActivity">
        <div class="table-wrap" >
            <div class="content">
                <div class="title-wrap">
                    <div class="select-wrap">
                        <el-select clearable v-model="state" @change="selectChange" placeholder="请选择方案状态">
                        <el-option
                            v-for="item in stateOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                        </el-select>
                    </div>
                    <div class="select-wrap">
                        <el-select clearable v-model="user_id" @change="selectUserChange" placeholder="请选择负责人筛选">
                        <el-option
                            v-for="item in userOptions"
                            :key="item.user_id"
                            :label="item.nickname"
                            :value="item.user_id">
                        </el-option>
                        </el-select>
                    </div>
                    <div class="search-wrap">
                        <el-input clearable v-model="keyword" placeholder="请输入搜索内容"></el-input>
                        <el-button @click="clickSearch" type="primary">搜索</el-button>
                    </div>
                </div>
                <div class="tag-wrap">
                    <div class="tw-item">
                        <div class="item" style="border: 1px solid #ddd;"></div>正常
                    </div>
                    <div class="tw-item">
                        <div class="item" style="background: #e6a23c"></div>即将过期
                    </div>
                    <div class="tw-item">
                        <div class="item" style="background: #f56c6c"></div>已过期
                    </div>
                    <div class="tw-item">
                        <div class="item" style="background: #ddd"></div>被停用
                    </div>
                </div>
            </div>
            
            <el-table :data="tableData" height="620" border style="width: 100%" v-loading="loading" :row-class-name="tableRowClassName">
                <el-table-column align="center" prop="id" label="ID" width="80"></el-table-column>
                <el-table-column align="center" prop="name" label="店铺名称" width="120" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column align="center" prop="shop_owner.username" label="店长" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column align="center" prop="shop_owner.mobile" label="联系电话" width="130" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column align="center" prop="enterprise_shop_rel.remark_shop_name" label="备注名称" width="100"></el-table-column>
                <el-table-column align="center" prop="enterprise_shop_rel.remark_shop_address" label="备注地区" width="140" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column align="center" label="方案名称" :show-overflow-tooltip="true">
                    <template slot-scope="scope">
                        <div>{{scope.row.activity_plan[0].pname}}</div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="time_end" label="首页轮播图" width="100">
                    <template slot-scope="scope">
                        <img height="60" @click="clickImage(scope.row.activity_plan[0].home_swiper)" 
                            v-if="scope.row.activity_plan[0].home_swiper !=null && scope.row.activity_plan[0].home_swiper.length>0" 
                            :src="scope.row.activity_plan[0].home_swiper[0].img" alt="">
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="create_timex" label="方案状态" :show-overflow-tooltip="true">
                    <template slot-scope="scope">
                        <div v-if="scope.row.activity_plan[0].state == 0">暂停</div>
                        <div v-if="scope.row.activity_plan[0].state == 1">进行中</div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="activity_count" label="活动总数" width="100"></el-table-column>
                <el-table-column align="center" prop="start_activity_count" label="活动进行中数" width="110"></el-table-column>
                <el-table-column align="center" prop="create_time" label="经销商发布时间"  width="120" :show-overflow-tooltip="true">
                    <template slot-scope="scope">
                        <div>{{scope.row.activity_plan[0].release_time}}</div>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="创建时间" :show-overflow-tooltip="true">
                    <template slot-scope="scope">
                        <div>{{scope.row.activity_plan[0].create_time}}</div>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="客服负责人" width="100" :show-overflow-tooltip="true">
                    <template slot-scope="scope">
                        <div v-if="scope.row.system_admin !=null">{{scope.row.system_admin.nickname}}</div>
                    </template>
                </el-table-column>
                <el-table-column fixed="right" align="center" prop="create_timex" label="操作" width="100">
                    <template slot-scope="scope">
                        <div class="operation-wrap">
                            <el-tooltip class="item" effect="dark" content="详情" placement="top">
                                <i @click="clickInfo(scope.row)" class="iconfont el-icon-document"></i>
                            </el-tooltip>
                            <el-tooltip v-if="userInfo.kf_level == 1 || userInfo.kf_level == 2"  class="item" effect="dark" content="创建门店记录" placement="top">
                                <i @click="clickRecord(scope.row)" class="iconfont el-icon-notebook-1"></i>
                            </el-tooltip>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination-wrap">
                <el-pagination
                    @current-change="handleCurrentChange"
                    :current-page.sync="page"
                    :page-size="50"
                    layout="total, prev, pager, next"
                    :total="total">
                </el-pagination>
            </div>
        </div>
        <div v-if="isShowImages">
            <el-image-viewer 
            :on-close="closeViewer" 
            :url-list="imageList" />
        </div>
        <!-- 跟进记录 -->
        <el-dialog title="创建门店跟进记录" 
            :visible.sync="isCreateRecord" 
            :before-close="handleClose"
            custom-class="dialog-wrap"
            :close-on-click-modal="false">
            <div class="content">
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                    <el-form-item label="标题" prop="title">
                        <el-input v-model="ruleForm.title"></el-input>
                    </el-form-item>
                    <el-form-item label="内容" prop="content">
                        <el-input type="textarea" v-model="ruleForm.content"></el-input>
                    </el-form-item>
                    <el-form-item label="联系人" prop="contact_name">
                        <el-input v-model="ruleForm.contact_name"></el-input>
                    </el-form-item>
                    <el-form-item label="手机/微信号" prop="contact">
                        <el-select v-model="ruleForm.contact" placeholder="请选择联系方式">
                            <el-option
                                v-for="item in contactOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
                <div class="btn-wrap">
                    <el-button :loading="btnLoading" @click="submitForm" type="primary">创 建</el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import common from "@/utils/common.js"
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
export default {
    components: {
      ElImageViewer,
    },
    data () {
        return {
            userInfo: {},
            keyword: '',
            state: '',
            page: 1,
            total: 0,
            tableData: [],
            stateOptions: [
                {
                    value: 0,
                    label: '暂停'
                },{
                    value: 1,
                    label: '进行中'
                }
            ],
            loading: false,
            isShowImages: false,
            imageList: [],
            plan_id: '',
            userOptions: [],
            user_id: '',
            isCreateRecord: false,
            shopId: '',
            ruleForm: {
                title: '',
                content: '',
                contact: '',
                contact_name: ''
            },
             rules: {
                title: [
                    { required: true, message: '请输入标题', trigger: 'blur' },
                ],
                content: [
                    { required: true, message: '请输入内容', trigger: 'blur' },
                ],
            },
            btnLoading: false,
            contactOptions: [{
                value: 1,
                label: '手机'
            },{
                value: 2,
                label: '微信'
            }]
        }
    },
    mounted () {
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
        let info = JSON.parse(localStorage.getItem('activity'));
        this.plan_id = info.id;
        this.getActivityList();
        this.getUserConditions();
    },
    methods: {
       // 确定创建
        submitForm(){
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    let params = {
                        shop_id: this.shopId,
                        title: this.ruleForm.title,
                        content: this.ruleForm.content,
                        contact: this.ruleForm.contact,
                        contact_name: this.ruleForm.contact_name,
                    }
                    this.btnLoading = true;
                    common.connect("/customerservicev1/shop_follow/store",params,(res)=>{
                        this.$message({
                            message: res.msg,
                            type: 'success'
                        });
                        this.$refs.ruleForm.resetFields();
                        this.btnLoading = false;
                        this.isCreateRecord = false;
                    });
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        selectUserChange(){
            this.page = 1;
            this.getActivityList();
        },
        // 关闭弹窗
        handleClose(){
            this.isCreateRecord = false;
        },
        // 写跟进记录
        clickRecord(row){
            this.shopId = row.shop_owner.shop_id
            this.isCreateRecord = true;
        },
        // 跳转详情
        clickInfo(row){
            localStorage.removeItem('shop')
            let newPage = this.$router.resolve({ 
                path: 'shopDataInfo', 
                query: { type: 'fifth' }
            });
            window.open(newPage.href, '_blank');
            localStorage.setItem('shop',JSON.stringify(row));
            localStorage.setItem('jump',5);
        },
        tableRowClassName({row, rowIndex}){
            if(row.shop_status === 2){
                return 'warning-row';
            }else if(row.shop_status === 3){
                return 'danger-row';
            }else if(row.shop_status === 4){
                return 'info-row';
            }
        },
        selectChange(){
            this.page = 1;
            this.getActivityList();
        },
        clickImage(item){
            this.isShowImages = true;
            item.forEach(v=>{
                this.imageList.push(v.img);
            })
        },
        // 关闭查看器
        closeViewer() {
            this.isShowImages = false;
            this.imageList = [];
        },
        // 搜索
        clickSearch(){
            this.page = 1;
            this.getActivityList();
        },
        // 获取客服数据
        getUserConditions(){
            common.connect("/customerservicev1/admin/userConditions",{},(res)=>{
                this.userOptions = res.data;
            });
        },
        // 获取活动列表
        getActivityList(){
            let params = {
                keyword: this.keyword,
                page: this.page,
                plan_id: this.plan_id,
                state: this.state,
                user_id: this.user_id
            }
            this.loading = true;
            common.connect("/customerservicev1/enterprise_activity/enterprisePlanJoinShopIndex",params,(res)=>{
                res.data.list.forEach(v => {
                    v.activity_plan.forEach(v1 => {
                        if(v1.home_swiper != null){
                            v1.home_swiper = JSON.parse(v1.home_swiper);
                        }
                        if(v1.home_poster != null){
                            v1.home_poster = JSON.parse(v1.home_poster);
                        }
                    })
                });
                this.tableData = res.data.list;
                this.total = res.data.count;
                this.loading = false;
            });
        },
        handleCurrentChange(val){
            this.page = val;
            this.getActivityList();
        }
    },
}
</script>

<style lang='scss'>
.participatingActivity{
    background: #fff;
    .table-wrap{
        .content{
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        .title-wrap{
            display: flex;
            align-items: center;
            padding-bottom: 10px;
            .select-wrap{
            margin-right: 20px;
            }
            .search-wrap{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            .el-input{
                width: 200px;
                margin-right: 10px;
            }
            }
        }
        .tag-wrap{
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding-bottom: 10px;
            margin-top: -10px;
            .tw-item{
            font-size: 14px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin-left: 10px;
            .item{
                margin-right: 5px;
                width: 12px;
                height: 12px;
                border-radius: 100%;
                background: #fff;
            }
            }
        }
    }
    .operation-wrap{
        .iconfont{
            font-size: 16px;
            padding: 0 10px;
            cursor: pointer;
        }
    }
    .pagination-wrap{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-top: 5px;
    }
    .dialog-wrap{
        width: 800px;
        .content{
            margin-left: 20px;
            padding: 30px 0;
            .el-input{
                width: 650px;
            }
            .el-textarea{
                width: 650px;
            }
        }
        .btn-wrap{
            display: flex;
            justify-content: center;
        }
    }
    .el-dialog{
        margin: 0;
        margin-top: 0vh !important;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        padding-bottom: 10px;
    }
    .el-table tbody tr:hover>td {
        background-color: transparent !important;
    }
    .el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf  {
        background-color: transparent !important;
    }
    .el-table__fixed-right tbody tr:hover>td {
        background-color: transparent !important;
    }
}
</style>
